









import { Component, Vue } from 'vue-property-decorator';
import { PATH_INDEX } from '@/router/type';

// 用户消息
@Component({
  name: 'PageNotFound',
  components: {}
})
export default class App extends Vue {
  pathIndex: string = PATH_INDEX
}
